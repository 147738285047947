import React from "react";
import { useUser } from "hooks/useUser";
import { useNavigate } from "react-router-dom";
import "./SettingsPage.scss";
import { ReactComponent as IconArrowLeft } from "images/chevron_left.svg";
import { ReactComponent as IconLeft } from "images/arrow_left.svg";
import { ReactComponent as IconPencil } from "images/pencil.svg";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";

const SettingsPage: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  if (!user) {
    return <LoadingScreen />;
  }

  const companyInfoFields = [
    {
      label: "Company Name",
      value: user.company_legal_name,
      key: "company_legal_name",
    },
    { label: "DOT", value: user.dot, key: "dot" },
    { label: "MC", value: user.mc, key: "mc" },
  ];

  const contactInfoFields = [
    { label: "First Name", value: user.first_name, key: "first_name" },
    { label: "Last Name", value: user.last_name, key: "last_name" },
    { label: "Email", value: user.email, key: "email", disabled: true },
    { label: "Phone", value: user.phone_number, key: "phone_number" },
    {
      label: "Business Location",
      value: user.company_address,
      key: "company_address",
    },
  ];

  const additionalInfoFields = [
    { label: "T-Code", value: user.t_code, key: "t_code" },
    { label: "Permits", value: user.permits.join(", "), key: "permits" },
    {
      label: "General Preferences",
      value: user.general_preferences.join(", "),
      key: "general_preferences",
    },
  ];

  const handleEdit = (key: string) => {
    navigate(`/settings/edit/${key}`);
  };

  return (
    <div className="settings-page">
      <div className="settings-page__container">
        <IconArrowLeft onClick={() => navigate("/")} className="back-icon" />
        <div onClick={() => navigate("/")} className="back-label offset">
          <IconLeft />
          <span>Back to Home</span>
        </div>
        <div className="title">Account settings</div>
        <div className="settings-list">
          <div className="settings-block">
            <div className="settings-list-title">Company Info</div>
            <div>
              {companyInfoFields.map(field => (
                <SettingsField field={field} handleEdit={handleEdit} />
              ))}
            </div>
          </div>
          <div className="settings-block">
            <div className="settings-list-title">Contact Info</div>
            <div>
              {contactInfoFields.map(field => (
                <SettingsField field={field} handleEdit={handleEdit} />
              ))}
            </div>
          </div>
          <div className="settings-block">
            <div className="settings-list-title">Additional Info</div>
            <div>
              {additionalInfoFields.map(field => (
                <SettingsField field={field} handleEdit={handleEdit} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IField {
  value?: string;
  label: string;
  key: string;
  disabled?: boolean;
}

const SettingsField = ({
  field,
  handleEdit,
}: {
  field: any;
  handleEdit: (key: string) => void;
}) => {
  return (
    <div
      className={`settings-field ${field.disabled ? "disabled" : ""}`}
      key={field.key}
      onClick={field?.disabled ? () => null : () => handleEdit(field.key)}
    >
      <div className="settings-field-left">
        <div className="label">{field.label}</div>
        <div className="value">{field.value || "-"}</div>
      </div>

      {!field.disabled && <IconPencil />}
    </div>
  );
};

export default SettingsPage;
