import React from "react";
import { PulseLoader } from "react-spinners";
import "./LoadingScreen.scss";

const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-screen">
      <PulseLoader
        color="#082980"
        loading={true}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoadingScreen;
