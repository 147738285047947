export const EVENTS = {
  NEW_SIGNUP: "New Signup",
  COMPANY_INFO_SAVED: "Company Info Saved",
  NEW_LOGIN: "New Login",
  REQUEST_SUBMITTED: "Request Submitted",
  LOAD_SEARCH_SUBMITTED: "Load Search Submitted",
} as const;

export const USER_PROPERTIES = {
  ACCOUNT_EMAIL: "Account Email",
  COMPANY_INFO: "Company Info",
} as const;

export const EVENT_PROPERTIES = {
  DEVICE_TYPE: "Device Type",
  REQUEST_INFO: "Request Info",
  FOUND_LOADS_AMOUNT: "Found Loads Amount",
} as const;
