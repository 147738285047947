import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { useUser } from "hooks/useUser";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { user } = useUser();
  const { currentUser } = useAuth();

  if (!currentUser || !user) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
