import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button/Button";
import "./InfoSaved.scss";
import { ReactComponent as IconCheckmark } from "images/checkmark.svg";

const InfoSaved: React.FC = () => {
  const navigate = useNavigate();

  const handleCloseSignup = () => {
    navigate("/");
  };

  return (
    <div className="form-wrapper">
      <div className="informative-screen form-container">
        <div className="title-subtitle">
          <IconCheckmark className="screen-icon" />
          <div className="title center">Company profile saved</div>
          <div className="subtitle center">
            You can update your company profile at any time.
          </div>
        </div>
        <div className="buttons-container">
          <Button text="Next" variant="primary" onClick={handleCloseSignup} />
        </div>
      </div>
    </div>
  );
};

export default InfoSaved;
