import React, { createContext, useState, useEffect } from "react";
import { User } from "../types/User";
import { getUserProfile } from "api/userService";
import { useAuth } from "./AuthContext";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { trackNewLogin } from "utils/mixpanel";
import { getDeviceType } from "utils/helpers";

interface UserContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loading: boolean;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const email = currentUser?.email;
    setLoading(true);

    if (email) {
      getUserProfile(email)
        .then((userData) => {
          setUser(userData);

          const deviceType = getDeviceType();
          trackNewLogin(userData.email, userData, deviceType);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {loading ? <LoadingScreen /> : children}
    </UserContext.Provider>
  );
};
