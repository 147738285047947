import React from "react";
import "./Home.scss";
import { Button } from "components/Button/Button";
import { ReactComponent as IconSearchLoads } from "images/search_loads.svg";
import { useNavigate } from "react-router-dom";
import { useUser } from "hooks/useUser";
import { useAuth } from "context/AuthContext";
import { ReactComponent as IconLogout } from "images/logout.svg";

const Home: React.FC = () => {
  const { user } = useUser();
  const { logout } = useAuth();

  const navigate = useNavigate();

  const handleFindLoads = () => {
    navigate("/request");
  };

  const handleSettings = () => {
    navigate("/settings");
  };

  return (
    <div className="home">
      <div className="home__container">
        <div className="home__top">
          <div className="title">Hi {user?.first_name}!</div>
        </div>

        <div className="home__card">
          <div className="title">{user?.company_legal_name}</div>
          <div className="home__card-info">
            <div className="home__card-info-item">
              <span className="home__card-info-title">DOT</span>
              <span className="home__card-info-description">
                {user?.dot || "-"}
              </span>
            </div>
            <div className="home__card-info-item">
              <span className="home__card-info-title">MC</span>
              <span className="home__card-info-description">
                {user?.mc || "-"}
              </span>
            </div>
          </div>
          <Button onClick={handleSettings} text="Update info" />
        </div>
        <div className="home__card">
          <IconSearchLoads className="search-loads-icon" />
          <div className="title">Better Loads Delivered to Your Inbox.</div>
          <div className="subtitle">
            The Empwr Assistant calls brokers to verify that spot loads are
            available and meet your requirements.
          </div>
          <Button onClick={handleFindLoads} text="Start" />
        </div>
        <div onClick={logout} className='logout-button'>
          <span>Log out</span>
          <IconLogout />
        </div>
      </div>
    </div>
  );
};

export default Home;
