import React, { createContext, useState, useContext } from "react";

interface RequestFormContextType {
  step: number;
  setStep: (step: number) => void;
  formData: any;
  setFormData: (data: any) => void;
}

const RequestFormContext = createContext<RequestFormContextType | undefined>(
  undefined,
);

export const useRequestFormContext = () => {
  const context = useContext(RequestFormContext);
  if (!context) {
    throw new Error(
      "useRequestFormContext must be used within a RequestFormProvider",
    );
  }
  return context;
};

export const RequestFormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<any>({});

  return (
    <RequestFormContext.Provider
      value={{ step, setStep, formData, setFormData }}
    >
      {children}
    </RequestFormContext.Provider>
  );
};
