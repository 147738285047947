import { toast } from "react-toastify";
import apiClient from "./config";
import { ISearchLoadsPayload } from "types/Payloads";

export const submitLaneSearch = async (data: ISearchLoadsPayload) => {
  try {
    const response = await apiClient.post("ai-caller/loads/verify", data);
    return response.data;
  } catch (error) {
    toast.error(
      `Failed to submit the search. ${(error as any)?.response?.data?.error || ""}`,
    );
    throw new Error("Failed to submit lane search");
  }
};
