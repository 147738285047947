import "./Button.scss";
import { ReactComponent as IconGoogle } from "images/google.svg";

interface IButtonProps {
  variant?: "primary" | "secondary" | "contrast";
  onClick?: () => void;
  text: string;
  className?: string;
  type?: "button" | "submit" | "reset";
  google?: boolean;
}

export const Button = ({
  variant = "primary",
  onClick,
  text,
  className,
  google,
  type = "button",
}: IButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`button ${variant} ${className}`}
    >
      {google && <IconGoogle />}
      <span>{text}</span>
    </button>
  );
};
