export const getDeviceType = (): "desktop" | "mobile" | "tablet" => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/mobile|android|iphone|ipod|blackberry|phone/.test(userAgent)) {
    return "mobile";
  } else if (/ipad|tablet/.test(userAgent)) {
    return "tablet";
  }
  return "desktop";
};
