import React from "react";
import MultiStepForm from "components/MultiStepForm/MultiStepForm";
import './Onboarding.scss';

const Onboarding: React.FC = () => {
  return (
    <div className="onboarding">
      <MultiStepForm />
    </div>
  );
};

export default Onboarding;
