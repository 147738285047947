import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import "./RequestPage.scss";
import AutoCompleteLocation from "components/AutoCompleteLocation/AutoCompleteLocation";
import { Button } from "components/Button/Button";
import * as Yup from "yup";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import { ReactComponent as IconArrowLeft } from "images/chevron_left.svg";
import { format } from "date-fns";
import { useRequestFormContext } from "context/RequestFormContext";
import { ReactComponent as IconCheckmark } from "images/checkmark.svg";
import { submitLaneSearch } from "api/apiLaneSearch";
import { useUser } from "hooks/useUser";
import {
  trackLoadSearchSubmitted,
  trackRequestSubmitted,
} from "utils/mixpanel";
import CallPrepareScreen from "pages/CallPrepareScreen/CallPrepareScreen";
import { toast } from "react-toastify";
import { submitSearchLoads } from "api/apiSearchLoads";
import { ISearchLoadsPayload } from "types/Payloads";
import { ILoad } from "types/Load";

const radiusOptions = [
  { value: "10", label: "10 mi" },
  { value: "25", label: "25 mi" },
  { value: "50", label: "50 mi" },
  { value: "100", label: "100 mi" },
  { value: "150", label: "150 mi" },
  { value: "200", label: "200 mi" },
  { value: "250", label: "250 mi" },
  { value: "500", label: "500 mi" },
  { value: "750", label: "750 mi" },
  { value: "1000", label: "1000 mi" },
];

export const trailerTypeOptions = [
  { value: "van", label: "Dry Van" },
  { value: "flatbed", label: "Flatbed" },
  { value: "reefer", label: "Reefer" },
  { value: "stepDeck", label: "Step Deck" },
  { value: "conestoga", label: "Conestoga" },
  { value: "tanker", label: "Tanker" },
  { value: "powerOnly", label: "Power Only" },
];

const requestDetailsSchema = Yup.object({
  pickupDateRange: Yup.array().nullable().required("Pickup date is required"),
  pickupLocation: Yup.string().required("Pickup location is required"),
  pickupRadius: Yup.string().required("Radius is required"),
  dropoffLocation: Yup.string().required("Dropoff location is required"),
  dropoffRadius: Yup.string().required("Radius is required"),
  targetRpm: Yup.number()
    .typeError("Must be a valid number")
    .required("Target RPM is required"),
  trailerType: Yup.string().required("Trailer type is required"),
  weightLimit: Yup.number()
    .typeError("Must be a valid number")
    .required("Weight limit is required"),
});

const loadSpecificationsSchema = Yup.object({
  requiredQuestions: Yup.string().optional(),
});

const RequestPage: React.FC = () => {
  const { step, setStep, formData, setFormData } = useRequestFormContext();
  const { user } = useUser();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSearchLoads, setLoadingSearchLoads] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [foundLoads, setFoundLoads] = useState<ILoad[]>([]);
  const [startDate, endDate] = dateRange;

  const getInitialValuesForStep = () => {
    switch (step) {
      case 0:
        return (
          formData[step] || {
            pickupDateRange: "",
            pickupLocation: "",
            pickupCoordinatesLat: 0,
            pickupCoordinatesLng: 0,
            pickupCountry: "",
            pickupState: "",
            pickupCity: "",
            pickupRadius: "",
            dropoffLocation: "",
            dropoffCoordinatesLat: 0,
            dropoffCoordinatesLng: 0,
            dropoffCountry: "",
            dropoffState: "",
            dropoffCity: "",
            dropoffRadius: "",
            targetRpm: "",
            trailerType: "",
            weightLimit: "",
            equipmentSpecs: "",
          }
        );
      case 1:
        return (
          formData[step] || {
            requiredQuestion1: "",
            requiredQuestion2: "",
            requiredQuestion3: "",
          }
        );
      default:
        return {};
    }
  };

  const handleNext = (values: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [step]: values,
    }));
    setStep(step + 1);
  };

  const handlePrevious = () => setStep(step - 1);

  const formatPayload = (questionsData?: any): ISearchLoadsPayload | null => {
    const questions = questionsData || formData[1];
    const requiredQuestions = [
      questions?.requiredQuestion1,
      questions?.requiredQuestion2,
      questions?.requiredQuestion3,
    ]
      .filter(question => question?.trim() !== "")
      .map(q => q);

    if (!formData[0]) return null;

    const payload = {
      user_id: user?.user_id,
      pickup: {
        address: formData[0].pickupLocation,
        start_datetime: format(formData[0].pickupDateRange[0], "yyyy-MM-dd"),
        end_datetime: format(formData[0].pickupDateRange[1], "yyyy-MM-dd"),
        radius: Number(formData[0].pickupRadius),
        coordinates_lat: formData[0].pickupCoordinatesLat,
        coordinates_lng: formData[0].pickupCoordinatesLng,
        country: formData[0].pickupCountry,
        state: formData[0].pickupState,
        city: formData[0].pickupCity,
      },
      dropoff: {
        address: formData[0].dropoffLocation,
        radius: formData[0].dropoffRadius
          ? Number(formData[0].dropoffRadius)
          : null,
        coordinates_lat: formData[0].dropoffCoordinatesLat,
        coordinates_lng: formData[0].dropoffCoordinatesLng,
        country: formData[0].dropoffCountry,
        state: formData[0].dropoffState,
        city: formData[0].dropoffCity,
      },
      preferences: {
        equipment_type: formData[0].trailerType,
        equipment_specs: formData[0].equipmentSpecs,
        weight_limit: formData[0].weightLimit,
        required_questions: requiredQuestions,
        dropoff_locations_to_avoid: [],
        target_rate_per_mile: formData[0].targetRpm,
      },
    };
    return payload;
  };

  const handleSubmitSearchLoads = async (questionsData: any) => {
    setFoundLoads([]);
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [step]: questionsData,
    }));

    const payload = formatPayload(questionsData);

    try {
      setLoadingSearchLoads(true);
      setStep(step + 1);
      const response = await submitSearchLoads(payload);

      if (response?.results) {
        setFoundLoads(response.results);
        trackLoadSearchSubmitted(
          user?.email || "",
          payload,
          response?.results?.length,
        );
      }
    } catch (error) {
      console.error("Failed to search loads:", error);
    } finally {
      setLoadingSearchLoads(false);
    }
  };

  const handleFinish = async () => {
    const payload: any = formatPayload();

    if (!foundLoads || foundLoads.length === 0) {
      toast.error("No loads found. Please try edit your request.");
      return;
    }

    payload.loads =
      foundLoads.length > 10 ? foundLoads.slice(0, 10) : [...foundLoads];

    try {
      setLoading(true);
      await submitLaneSearch(payload);
      trackRequestSubmitted(user?.email || "", payload);
      setStep(step + 1);
    } catch (error) {
      console.error("Failed to submit final data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleHome = () => {
    setFormData({});
    setStep(0);
    navigate("/");
  };

  const formattedPayload = formatPayload();

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Formik
            initialValues={getInitialValuesForStep()}
            validationSchema={requestDetailsSchema}
            onSubmit={handleNext}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="form-wrapper">
                  <div className="form-screen form-container">
                    <div className="fields-container">
                      <IconArrowLeft
                        onClick={handleHome}
                        className="back-icon"
                      />
                      <div className="title">Request Details</div>
                      <div className="row-fields">
                        <div className="form-group">
                          <label htmlFor="pickupDateRange">
                            Pickup Date Range
                          </label>
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate || undefined}
                            endDate={endDate || undefined}
                            onChange={update => {
                              setDateRange(
                                update as [Date | null, Date | null],
                              );
                              setFieldValue("pickupDateRange", update);
                            }}
                            className="input"
                            dateFormat="MMM dd"
                            placeholderText="Select dates"
                            customInput={
                              <input
                                type="text"
                                value={
                                  startDate && endDate
                                    ? `${format(startDate, "MMM dd")} - ${format(endDate, "MMM dd")}`
                                    : startDate
                                      ? `${format(startDate, "MMM dd")}`
                                      : ""
                                }
                                readOnly
                                className="input"
                              />
                            }
                          />
                          <ErrorMessage
                            name="pickupDateRange"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="row-fields">
                        <div className="form-group">
                          <label htmlFor="pickupLocation">
                            Pickup Location
                          </label>
                          <AutoCompleteLocation
                            value={values.pickupLocation}
                            onChange={locationData => {
                              setFieldValue(
                                "pickupLocation",
                                locationData.address,
                              );
                              setFieldValue(
                                "pickupCoordinatesLat",
                                locationData.coordinates_lat,
                              );
                              setFieldValue(
                                "pickupCoordinatesLng",
                                locationData.coordinates_lng,
                              );
                              setFieldValue(
                                "pickupCountry",
                                locationData.country,
                              );
                              setFieldValue("pickupState", locationData.state);
                              setFieldValue("pickupCity", locationData.city);
                            }}
                            placeholder="Enter location"
                          />
                          <ErrorMessage
                            name="pickupLocation"
                            component="div"
                            className="error-message"
                          />
                        </div>

                        <div className="form-group small">
                          <label htmlFor="pickupRadius">Radius</label>
                          <Select
                            name="pickupRadius"
                            options={radiusOptions}
                            value={radiusOptions.find(
                              option => option.value === values.pickupRadius,
                            )}
                            onChange={option =>
                              setFieldValue("pickupRadius", option?.value)
                            }
                            classNamePrefix="select"
                            placeholder="Radius"
                          />
                          <ErrorMessage
                            name="pickupRadius"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="row-fields">
                        <div className="form-group">
                          <label htmlFor="dropoffLocation">
                            Dropoff Location
                          </label>
                          <AutoCompleteLocation
                            value={values.dropoffLocation}
                            onChange={locationData => {
                              setFieldValue(
                                "dropoffLocation",
                                locationData.address,
                              );
                              setFieldValue(
                                "dropoffCoordinatesLat",
                                locationData.coordinates_lat,
                              );
                              setFieldValue(
                                "dropoffCoordinatesLng",
                                locationData.coordinates_lng,
                              );
                              setFieldValue(
                                "dropoffCountry",
                                locationData.country,
                              );
                              setFieldValue("dropoffState", locationData.state);
                              setFieldValue("dropoffCity", locationData.city);
                            }}
                            placeholder="Enter location"
                          />
                          <ErrorMessage
                            name="dropoffLocation"
                            component="div"
                            className="error-message"
                          />
                        </div>

                        <div className="form-group small">
                          <label htmlFor="dropoffRadius">Radius</label>
                          <Select
                            name="dropoffRadius"
                            options={radiusOptions}
                            value={radiusOptions.find(
                              option => option.value === values.dropoffRadius,
                            )}
                            onChange={option =>
                              setFieldValue("dropoffRadius", option?.value)
                            }
                            classNamePrefix="select"
                            placeholder="Radius"
                          />
                          <ErrorMessage
                            name="dropoffRadius"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="targetRpm">
                          Target Rate per Mile ($)
                        </label>
                        <Field
                          name="targetRpm"
                          type="number"
                          className="input"
                          placeholder="0.00"
                          step="0.01"
                        />
                        <ErrorMessage
                          name="targetRpm"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <div className="row-fields">
                        <div className="form-group">
                          <label htmlFor="trailerType">Trailer Type</label>
                          <Select
                            name="trailerType"
                            options={trailerTypeOptions}
                            value={trailerTypeOptions.find(
                              option => option.value === values.trailerType,
                            )}
                            onChange={option =>
                              setFieldValue("trailerType", option?.value)
                            }
                            classNamePrefix="select"
                            placeholder="Select trailer type"
                          />
                          <ErrorMessage
                            name="trailerType"
                            component="div"
                            className="error-message"
                          />
                        </div>

                        <div className="form-group small">
                          <label htmlFor="weightLimit">
                            Weight Limit (lbs)
                          </label>
                          <Field
                            name="weightLimit"
                            type="number"
                            className="input"
                            placeholder="Enter weight"
                          />

                          <ErrorMessage
                            name="weightLimit"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="equipmentSpecs">
                          Equipment Specifications (optional)
                        </label>
                        <Field
                          name="equipmentSpecs"
                          type="text"
                          className="input"
                          placeholder="Ex. vented van, air ride, etc."
                        />
                      </div>
                      <div className="buttons-container static">
                        <Button
                          text="Home"
                          variant="secondary"
                          onClick={handleHome}
                          className="desktop-only"
                        />
                        <Button type="submit" text="Next" variant="primary" />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );
      case 1:
        return (
          <Formik
            initialValues={getInitialValuesForStep()}
            validationSchema={loadSpecificationsSchema}
            onSubmit={handleSubmitSearchLoads}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="form-wrapper">
                  <div className="form-screen form-container">
                    <IconArrowLeft
                      onClick={handlePrevious}
                      className="back-icon"
                    />
                    <div className="fields-container">
                      <div className="title">Load Specifications</div>

                      {/* Required Questions */}
                      <div className="form-group">
                        <label htmlFor="requiredQuestion1">
                          Required Questions (optional)
                        </label>
                        <div className="form-subtitle subtitle">
                          These are questions you would want answered about
                          loads. Enter up to 3 questions.
                        </div>
                        <div className="multiform">
                          <Field
                            name="requiredQuestion1"
                            type="text"
                            as="textarea"
                            className="input"
                            placeholder="Enter a question"
                            rows={4}
                          />
                          <Field
                            name="requiredQuestion2"
                            type="text"
                            as="textarea"
                            className="input"
                            placeholder="Enter a question"
                            rows={4}
                          />
                          <Field
                            name="requiredQuestion3"
                            type="text"
                            as="textarea"
                            className="input"
                            placeholder="Enter a question"
                            rows={4}
                          />
                        </div>
                        <ErrorMessage
                          name="requiredQuestion1"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="buttons-container">
                      <Button
                        text="Back"
                        variant="secondary"
                        onClick={handlePrevious}
                        className="desktop-only"
                      />
                      <Button type="submit" text="Next" variant="primary" />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );
      case 2:
        return (
          <CallPrepareScreen
            handleBack={handlePrevious}
            handleEditRequest={() => setStep(0)}
            loading={loadingSearchLoads}
            handleSubmit={handleFinish}
            loads={foundLoads}
            payload={formattedPayload}
          />
        );
      case 3:
        return (
          <div className="form-wrapper">
            <div className="informative-screen form-container">
              <div className="title-subtitle">
                <IconCheckmark className="screen-icon" />
                <div className="title center">Request submitted!</div>
                <div className="subtitle center">
                  Your automated assistant is reaching out to brokers now.
                  You'll receive an email with the results in a few minutes
                </div>
              </div>
              <div className="buttons-container">
                <Button text="Home" variant="primary" onClick={handleHome} />
              </div>
            </div>
          </div>
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  if (loading) return <LoadingScreen />;

  return <div className="request-page">{renderStep()}</div>;
};

export default RequestPage;
