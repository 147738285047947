import React, { useEffect, useState, useRef } from "react";

interface AutoCompleteLocationProps {
  value: string;
  onChange: (locationData: {
    address: string;
    coordinates_lat: number;
    coordinates_lng: number;
    country: string;
    state: string;
    city: string;
  }) => void;
  placeholder?: string;
  className?: string;
}

const AutoCompleteLocation: React.FC<AutoCompleteLocationProps> = ({
  value,
  onChange,
  placeholder = "Enter a location",
  className = "input",
}) => {
  const autoCompleteRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
      console.error("Google Maps API key not found in environment variables");
      return;
    }

    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.head.appendChild(script);
    } else {
      setScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (scriptLoaded && inputRef.current) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: ["us", "ca"] }, // Restrict to USA and Canada
        },
      );

      autoCompleteRef.current.addListener("place_changed", () => {
        handlePlaceSelect();
      });
    }
  }, [scriptLoaded]);

  const handlePlaceSelect = () => {
    const place = autoCompleteRef.current.getPlace();
    if (!place || !place.geometry) {
      setTimeout(() => {
        const retryPlace = autoCompleteRef.current.getPlace();
        if (retryPlace && retryPlace.geometry) {
          processPlace(retryPlace);
        } else {
          console.error("Place details not fully loaded.");
        }
      }, 200);
    } else {
      processPlace(place);
    }
  };

  const processPlace = (place: any) => {
    const locationData = {
      address: place.formatted_address || "",
      coordinates_lat: place.geometry.location.lat(),
      coordinates_lng: place.geometry.location.lng(),
      country: "",
      state: "",
      city: "",
    };

    place.address_components?.forEach((component: any) => {
      const types = component.types;
      if (types.includes("country")) {
        locationData.country = component.short_name;
      }
      if (types.includes("administrative_area_level_1")) {
        locationData.state = component.short_name;
      }
      if (types.includes("locality")) {
        locationData.city = component.long_name;
      }
    });

    onChange(locationData);
  };

  return (
    <input
      ref={inputRef}
      type="text"
      value={value}
      onChange={e => onChange({ address: e.target.value, coordinates_lat: 0, coordinates_lng: 0, country: '', state: '', city: '' })}
      placeholder={placeholder}
      className={className}
    />
  );
};

export default AutoCompleteLocation;
