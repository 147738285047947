import mixpanel from "mixpanel-browser";
import { EVENTS, USER_PROPERTIES, EVENT_PROPERTIES } from "./mixpanelConstants";
import { UserProfileData } from "api/userProfile";

const identifyUser = (userId: string) => {
  mixpanel.identify(userId);
  mixpanel.people.set({ $email: userId });
};

export const trackNewSignup = (email?: string): void => {
  mixpanel.track(EVENTS.NEW_SIGNUP, {
    [USER_PROPERTIES.ACCOUNT_EMAIL]: email || "--",
  });

  if (email) {
    identifyUser(email);

    mixpanel.people.set({
      $email: email,
    });
  }
};

export const trackCompanyInfoSaved = (
  email: string,
  companyInfo: UserProfileData,
): void => {
  identifyUser(email);

  mixpanel.track(EVENTS.COMPANY_INFO_SAVED, {
    [USER_PROPERTIES.ACCOUNT_EMAIL]: email,
    [USER_PROPERTIES.COMPANY_INFO]: companyInfo,
  });

  mixpanel.people.set({
    $email: email,
    [USER_PROPERTIES.COMPANY_INFO]: companyInfo,
  });
};

export const trackNewLogin = (
  email: string,
  companyInfo: UserProfileData,
  deviceType: string,
): void => {
  identifyUser(email);

  mixpanel.track(EVENTS.NEW_LOGIN, {
    [USER_PROPERTIES.ACCOUNT_EMAIL]: email,
    [EVENT_PROPERTIES.DEVICE_TYPE]: deviceType,
  });

  mixpanel.people.set({
    $email: email,
    [USER_PROPERTIES.COMPANY_INFO]: companyInfo,
  });
};

export const trackLoadSearchSubmitted = (
  email: string,
  requestInfo: any,
  foundLoadsAmount: number,
): void => {
  mixpanel.track(EVENTS.LOAD_SEARCH_SUBMITTED, {
    [USER_PROPERTIES.ACCOUNT_EMAIL]: email,
    [EVENT_PROPERTIES.FOUND_LOADS_AMOUNT]: foundLoadsAmount,
    // Pickup details
    "Pickup Address": requestInfo?.pickup?.address,
    "Pickup Start Date": requestInfo?.pickup?.start_datetime,
    "Pickup End Date": requestInfo?.pickup?.end_datetime,
    "Pickup Radius": requestInfo?.pickup?.radius,

    // Dropoff details
    "Dropoff Address": requestInfo?.dropoff?.address,
    "Dropoff Radius": requestInfo?.dropoff?.radius || "Not Specified",

    // Preferences
    "Trailer Type": requestInfo?.preferences?.equipment_type,
    "Equipment Specifications":
      requestInfo?.preferences?.equipment_specs || "None",
    "Weight Limit": requestInfo?.preferences?.weight_limit,
    "Required Questions":
      JSON.stringify(requestInfo?.preferences?.required_questions) || "None",
    "Dropoff Locations to Avoid":
      JSON.stringify(requestInfo?.preferences?.dropoff_locations_to_avoid) ||
      "None",
    "Target Rate Per Mile": requestInfo?.preferences?.target_rate_per_mile,
  });
};

export const trackRequestSubmitted = (
  email: string,
  requestInfo: any,
): void => {
  mixpanel.track(EVENTS.REQUEST_SUBMITTED, {
    [USER_PROPERTIES.ACCOUNT_EMAIL]: email,
    // Pickup details
    "Pickup Address": requestInfo?.pickup?.address,
    "Pickup Start Date": requestInfo?.pickup?.start_datetime,
    "Pickup End Date": requestInfo?.pickup?.end_datetime,
    "Pickup Radius": requestInfo?.pickup?.radius,

    // Dropoff details
    "Dropoff Address": requestInfo?.dropoff?.address,
    "Dropoff Radius": requestInfo?.dropoff?.radius || "Not Specified",

    // Preferences
    "Trailer Type": requestInfo?.preferences?.equipment_type,
    "Equipment Specifications":
      requestInfo?.preferences?.equipment_specs || "None",
    "Weight Limit": requestInfo?.preferences?.weight_limit,
    "Required Questions":
      JSON.stringify(requestInfo?.preferences?.required_questions) || "None",
    "Dropoff Locations to Avoid":
      JSON.stringify(requestInfo?.preferences?.dropoff_locations_to_avoid) ||
      "None",
    "Target Rate Per Mile": requestInfo?.preferences?.target_rate_per_mile,
  });
};
