import apiClient from "./config";

export const getUserProfile = async (email: string) => {
  try {
    const encodedEmail = encodeURIComponent(email);
    const response = await apiClient.get(
      `ai-caller/user-profile?email=${encodedEmail}`,
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user profile");
  }
};
