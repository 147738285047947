import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "context/UserContext";
import { FormProvider } from "context/FormContext";
import { RequestFormProvider } from "context/RequestFormContext";
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from "context/AuthContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <FormProvider>
          <RequestFormProvider>
            <App />
            <ToastContainer />

          </RequestFormProvider>
        </FormProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
