import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import InputMask from "react-input-mask";
import { ActionMeta, MultiValue } from "react-select";
import { useFormContext } from "context/FormContext";
import {
  companyDetailsSchema,
  contactInfoSchema,
  additionalDetailsSchema,
} from "validation/schemas";
import { useLocation, useNavigate } from "react-router-dom";
import "./MultiStepForm.scss";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import { Button } from "components/Button/Button";
import AutoCompleteLocation from "components/AutoCompleteLocation/AutoCompleteLocation";
import { useUser } from "hooks/useUser";
import CreatableSelect from "react-select/creatable";
import { ReactComponent as IconArrowLeft } from "images/chevron_left.svg";
import { ReactComponent as IconCheckmark } from "images/checkmark.svg";
import { ReactComponent as IconLogo } from "images/big_logo.svg";
import { ReactComponent as IconTruck } from "images/icon_truck_big_new.svg";
import { createUserProfile, UserProfileData } from "api/userProfile";
import { permitOptions } from "utils/constants";
import { useAuth } from "context/AuthContext";
import { trackCompanyInfoSaved } from "utils/mixpanel";

interface Option {
  value: string;
  label: string;
}

const MultiStepForm: React.FC = () => {
  const { step, setStep, formData, setFormData } = useFormContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (user) {
      setStep(5);
    }
  }, [user]);

  const getInitialValuesForStep = () => {
    switch (step) {
      case 2:
        return formData[step] || { company_name: "", dot: "", mc: "" };
      case 3:
        return (
          formData[step] || {
            first_name: "",
            last_name: "",
            company_phone: "",
            business_location: "",
          }
        );
      case 4:
        return (
          formData[step] || {
            t_code: "",
            permits: [],
            general_preferences: "",
          }
        );
      default:
        return {};
    }
  };

  const handleNext = (values: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [step]: values,
    }));
    setStep(step + 1);
  };

  const handlePrevious = () => setStep(step - 1);

  const combineFormData = (data: any) => {
    const combinedData = {
      ...data[2], // Company details
      ...data[3], // Contact info
      ...data[4], // Additional details
    };

    if (combinedData.company_phone) {
      combinedData.company_phone = combinedData.company_phone.replace(
        /\D/g,
        "",
      );
    }

    if (combinedData.permits && Array.isArray(combinedData.permits)) {
      combinedData.permits = combinedData.permits.join(", ");
    }

    return combinedData;
  };

  const handleFinishOnboarding = async (values: any) => {
    const data = {
      ...formData,
      [step]: values,
    };

    const finalFormData = combineFormData(data);

    // Format the data according to the API requirements
    const payload: UserProfileData = {
      email: currentUser?.email || "",
      phone_number: finalFormData.company_phone.replace(/\D/g, ""),
      dot: JSON.stringify(finalFormData.dot),
      mc: JSON.stringify(finalFormData.mc),
      first_name: finalFormData.first_name,
      last_name: finalFormData.last_name,
      company_legal_name: finalFormData.company_name,
      company_address: finalFormData.business_location,
      t_code: finalFormData.t_code,
      permits: finalFormData.permits ? finalFormData.permits.split(", ") : [],
      general_preferences: finalFormData.general_preferences
        ? [finalFormData.general_preferences]
        : [],
    };

    navigate("/signup-form", { state: { payload } });
  };

  const handleCloseOnboarding = () => {
    navigate("/");
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div className="form-wrapper primary-bg">
            <div className="informative-screen form-container">
              <div className="title-subtitle">
                <IconLogo className="big-logo" />
                <div className="title contrast center">
                  Welcome to the EMPWR Trucking Assistant!
                </div>
              </div>
              <div className="buttons-container">
                <Button
                  text="Get started"
                  variant="contrast"
                  onClick={() => setStep(step + 1)}
                />
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="form-wrapper">
            <div className="informative-screen form-container">
              <IconArrowLeft onClick={handlePrevious} className="back-icon" />
              <div className="title-subtitle">
                <IconTruck className="screen-icon" />

                <div className="title center">
                  Let's start by setting up your company profile
                </div>
                <div className="subtitle center">
                  This information will be used by the Empwr Trucking Assistant
                  to communicate with brokers on your behalf.
                </div>
                <div
                  style={{ marginTop: 10 }}
                  className="account-text desktop-only"
                >
                  <div className="text">Already have an account? </div>
                  <div className="link" onClick={() => navigate("/login")}>
                    Log in
                  </div>
                </div>
              </div>
              <div className="buttons-container">
                <Button
                  text="Back"
                  variant="secondary"
                  onClick={handlePrevious}
                  className="desktop-only"
                />
                <Button
                  text="Start"
                  variant="primary"
                  onClick={() => setStep(step + 1)}
                />
                <div className="account-text mobile-only">
                  <div className="text">Already have an account? </div>
                  <div className="link" onClick={() => navigate("/login")}>
                    Log in
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <Formik
            initialValues={getInitialValuesForStep()}
            validationSchema={companyDetailsSchema}
            onSubmit={handleNext}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <div className="form-wrapper">
                  <div className="form-screen form-container">
                    <IconArrowLeft
                      onClick={handlePrevious}
                      className="back-icon"
                    />

                    <div className="fields-container">
                      <div className="title">Company info</div>
                      <div className="form-group">
                        <label htmlFor="company_name">Company Name</label>
                        <div>
                          <Field
                            id="company_name"
                            name="company_name"
                            type="text"
                            className="input"
                            value={values.company_name || ""}
                            placeholder="Company name"
                          />
                        </div>
                        <ErrorMessage
                          name="company_name"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="dot">DOT</label>
                        <div>
                          <Field
                            id="dot"
                            name="dot"
                            type="number"
                            className="input"
                            value={values.dot || ""}
                            placeholder="Ex. 1234567"
                          />
                        </div>
                        <ErrorMessage
                          name="dot"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="mc">MC</label>
                        <div>
                          <Field
                            id="mc"
                            name="mc"
                            type="number"
                            className="input"
                            value={values.mc || ""}
                            placeholder="Ex. 654321"
                          />
                        </div>
                        <ErrorMessage
                          name="mc"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="buttons-container">
                      <Button
                        text="Back"
                        variant="secondary"
                        onClick={handlePrevious}
                        className="desktop-only"
                      />
                      <Button type="submit" text="Next" variant="primary" />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );
      case 3:
        return (
          <Formik
            initialValues={getInitialValuesForStep()}
            validationSchema={contactInfoSchema}
            onSubmit={handleNext}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="form-wrapper">
                  <div className="form-screen form-container">
                    <IconArrowLeft
                      onClick={handlePrevious}
                      className="back-icon"
                    />
                    <div className="fields-container">
                      <div className="title">Contact info</div>

                      <div className="row-fields">
                        <div className="form-group">
                          <label htmlFor="first_name">First Name</label>
                          <div>
                            <Field
                              id="first_name"
                              name="first_name"
                              type="text"
                              placeholder="First Name"
                              className="input"
                              value={values.first_name || ""}
                            />
                          </div>
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="last_name">Last Name</label>
                          <div>
                            <Field
                              id="last_name"
                              name="last_name"
                              placeholder="Last Name"
                              type="text"
                              className="input"
                              value={values.last_name || ""}
                            />
                          </div>
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="company_phone">Phone</label>
                        <div>
                          <InputMask
                            placeholder="(000) 000-0000"
                            mask="(999) 999-9999"
                            value={values.company_phone}
                            onChange={e =>
                              setFieldValue("company_phone", e.target.value)
                            }
                            maskChar={null}
                          >
                            {/* @ts-ignore  */}
                            {inputProps => (
                              <Field
                                {...inputProps}
                                id="company_phone"
                                name="company_phone"
                                type="text"
                                className="input"
                              />
                            )}
                          </InputMask>

                          <ErrorMessage
                            name="company_phone"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="business_location">
                          Business Location
                        </label>
                        <div>
                          <AutoCompleteLocation
                            value={values.business_location}
                            onChange={location =>
                              setFieldValue(
                                "business_location",
                                location.address,
                              )
                            }
                            placeholder="Enter city"
                          />
                        </div>
                        <ErrorMessage
                          name="business_location"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="buttons-container">
                      <Button
                        text="Back"
                        variant="secondary"
                        onClick={handlePrevious}
                        className="desktop-only"
                      />
                      <Button type="submit" text="Next" variant="primary" />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );
      case 4:
        return (
          <Formik
            initialValues={getInitialValuesForStep()}
            validationSchema={additionalDetailsSchema}
            onSubmit={handleFinishOnboarding}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="form-wrapper">
                  <div className="form-screen form-container">
                    <IconArrowLeft
                      onClick={handlePrevious}
                      className="back-icon"
                    />
                    <div className="fields-container">
                      <div className="title">Additional info</div>

                      <div className="form-group">
                        <label htmlFor="t_code">CHR T-Code</label>
                        <div>
                          <Field
                            id="t_code"
                            name="t_code"
                            type="text"
                            className="input"
                            value={values.t_code || ""}
                            placeholder="Ex. T12345"
                          />
                        </div>
                      </div>

                      {/* Permits Multi-Select Dropdown */}
                      <div className="form-group">
                        <label htmlFor="permits">Permits (optional)</label>
                        <CreatableSelect
                          isMulti
                          name="permits"
                          options={permitOptions}
                          placeholder="Select or enter permits"
                          classNamePrefix="select"
                          value={permitOptions
                            .filter(option =>
                              Array.isArray(values.permits)
                                ? values.permits.includes(option.value)
                                : [],
                            )
                            .concat(
                              Array.isArray(values.permits)
                                ? values.permits
                                    .filter(
                                      (value: string) =>
                                        !permitOptions.some(
                                          option => option.value === value,
                                        ),
                                    )
                                    .map((value: string) => ({
                                      value,
                                      label: value,
                                    }))
                                : [],
                            )}
                          onChange={(
                            selectedOptions: MultiValue<Option>,
                            actionMeta: ActionMeta<Option>,
                          ) =>
                            setFieldValue(
                              "permits",
                              selectedOptions
                                ? selectedOptions.map(option => option.value)
                                : [],
                            )
                          }
                        />
                        <ErrorMessage
                          name="permits"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="general_preferences">
                          General Preferences (optional)
                        </label>
                        <Field
                          placeholder="Ex. No driver assist, live load/unload only, etc."
                          id="general_preferences"
                          name="general_preferences"
                          as="textarea"
                          rows={4}
                          className="input"
                          value={values.general_preferences || ""}
                        />
                      </div>
                    </div>
                    <div className="buttons-container">
                      <Button
                        text="Back"
                        variant="secondary"
                        onClick={handlePrevious}
                        className="desktop-only"
                      />
                      <Button type="submit" text="Finish" variant="primary" />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );
      case 5:
        return (
          <div className="form-wrapper">
            <div className="informative-screen form-container">
              <div className="title-subtitle">
                <IconCheckmark className="screen-icon" />
                <div className="title center">Company profile saved</div>
                <div className="subtitle center">
                  You can update your company profile at any time.
                </div>
              </div>
              <div className="buttons-container">
                <Button
                  text="Next"
                  variant="primary"
                  onClick={handleCloseOnboarding}
                />
              </div>
            </div>
          </div>
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  if (loading) return <LoadingScreen />;

  return renderStep();
};

export default MultiStepForm;
