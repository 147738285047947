import apiClient from "./config";

interface UserProfileUpdateData {
  id: string;
  email: string;
  phone_number: string;
  dot: string;
  mc: string;
  first_name: string;
  last_name: string;
  company_legal_name: string;
  company_address: string;
  t_code: string;
  permits: string[];
  general_preferences: string[];
}

export const updateUserProfile = async (data: UserProfileUpdateData) => {
  try {
    const response = await apiClient.put("ai-caller/user-profile", data);
    return response.data;
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};
